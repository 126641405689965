import { v4 as uuidv4 } from "uuid";

const songData = [
    {
        title: "Waltz of the Flowers",
        artist: "Tchaikovsky",
        coverUrl:
            "https://api.audiohut.ackr8.com/Classic/1.png",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Classic/1.png",
        audio: "https://api.audiohut.ackr8.com/Classic/1.mp3",
        palette: "coral",
        id: uuidv4(),
    },
    {
        title: "Mohe Rang do Laal",
        artist: "Shreya",
        coverUrl:
            "https://api.audiohut.ackr8.com/Classic/2.jpeg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Classic/2.jpeg",
        audio: "https://api.audiohut.ackr8.com/Classic/2.mp3",
        palette: "yellow",
        id: uuidv4(),
    },
    {
        title: "Bairi Piya",
        artist: "Shreya Ghoshal",
        coverUrl:
            "https://api.audiohut.ackr8.com/Classic/3.jpg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Classic/3.jpg",
        audio: "https://api.audiohut.ackr8.com/Classic/3.mp3",
        palette: "purple",
        id: uuidv4(),
    },
    {
        title: "Ghar More Pardesia",
        artist: "Shreya Ghoshal",
        coverUrl:
            "https://api.audiohut.ackr8.com/Classic/4.jpeg",
        thumbUrl:
            "https://api.audiohut.ackr8.com/Classic/4.jpeg",
        audio: "https://api.audiohut.ackr8.com/Classic/4.mp3",
        palette: "green",
        id: uuidv4(),
    },


];

export default songData;